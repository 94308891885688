import $ from 'jquery';

export default function () {

	$('.c-tabs__menu a').click(function(e) {
		e.preventDefault();

		var tab = $(this).attr('data-tab');

		$('.js-tab').removeClass('active');
		$('.c-tabs__menu a').removeClass('active');
		$(this).addClass('active');
		$('.js-tab[data-tab="'+ tab +'"]').addClass('active');
	});
}