import $ from 'jquery';

export default function () {

	$('.c-header__toggle').click(function() {

		var $headerMobile = $('.c-header__mobile');

		if($headerMobile.hasClass('active')) {
			$headerMobile.removeClass('active');
		} else {
			$headerMobile.addClass('active');
		}
	});

	$('.c-header__mobile-close').click(function() {
		$('.c-header__mobile').removeClass('active');
	});
}
