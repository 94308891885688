import $ from 'jquery';

export default function () {

    // Calcule la dimension des images du slider
	function bannerHeight() {

		var wHeight = $(window).height();
		var hHeight = $('.c-header').innerHeight();

		$('.c-single__image').css('height', wHeight - hHeight - 400 + 'px');

	}

    // Calcule la dimension des images thumbnail du slider
    function thumbnailSize(){
        var tWidth = $('.thumbnail_slide').width();

        $('.thumbnail_slide').css('height', tWidth , '!important');

        if($(window).width() < 767){
            $('.thumbnail_slide').css({
                'margin-bottom': '24px',
                'width': '100%',
                'height': '300px',
                'background-position': 'center center',
                'background-repeat': 'no-repeat',
                'background-size': 'cover'
            });
        }
    }

    thumbnailSize();

	bannerHeight();

	//Recalcule la dimension des images des sliders
	$(window).resize(function() {

        bannerHeightAccueil();
		bannerHeight();
        thumbnailSize();

	});

	//Calcul la dimension des images thumbnail du slider en mode tablette
    $('.js-single-link').on('click', function(){
        if($(window).width() > 767){
            setTimeout(thumbnailSize, 2500);
        }
        else{
            return null;
        }
    });

    //Calcul la dimension des images thumbnail du slider en mode desktop
    $('.js-single-link').on('click', function(){
        if($(window).width() < 768){
            setTimeout(thumbnailSize, 2500);
        }
        else{
            return null;
        }
    });

    //Calcule la dimension de la bannière de la page d'accueil
    function bannerHeightAccueil() {

        var wHeight = $(window).height();
        var hHeight = $('.c-header').innerHeight();

        $('.c-banner').css('height', wHeight - hHeight - 40 + 'px');
        $('.c-banner').css('height', wHeight - hHeight - 40 + 'px');

    }

    bannerHeightAccueil();

	$('.c-button--arrow').mouseover(function() {

		var textWidth = $(this).find('span').innerWidth();
		var iconWidth = $(this).find('.fa').width();

		$(this).find('.c-button__red-border').css('width', textWidth + iconWidth + 'px');

	});

	$('.c-button--arrow').mouseout(function() {

		var textWidth = $(this).find('span').innerWidth();
		var iconWidth = $(this).find('.fa').width();

		$(this).find('.c-button__red-border').css('width', '15px');

	});



}