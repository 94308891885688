import $ from 'jquery';

export default function () {


	$(document).scroll(function() {

		if($(window).width() >= 767) {
		
			var offset = $(document).scrollTop();
			var $header = $('.c-header');

			if(offset >= 250) {
				$header.addClass('hide');
				$('body').css('margin-top', $header.outerHeight() + 'px' );
			} else {
				$header.removeClass('hide show');
				$('body').css('margin-top', 0 + 'px' );
			};

			if(offset >= 350) {
				$header.addClass('show');
			}

		 	if(offset >= 450) {
				$header.addClass('sticky');
			} else {
				$header.removeClass('sticky');
			};
		};
	});
}