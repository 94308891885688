import $ from 'jquery';

export default function () {

	$('.js-single-link').click(function(e) {
		e.preventDefault();

		var realisation = $(this).attr('data-realisation');

    	$('body').css('overflow', 'hidden');
		$('.js-single').removeClass('active');
		$('.c-single__wrapper').addClass('active');
		$('.js-single[data-realisation="'+ realisation +'"]').addClass('active');

	});

	$('.c-single__link').click(function(e) {
		e.preventDefault();

		$(this).closest('.js-single').removeClass('active').next('li').addClass('active');
	});

	$('.c-single__close').click(function(e) {
		e.preventDefault();

		$('body').css('overflow', 'visible');
		$('.c-single__wrapper').removeClass('active');;
	});

    $(document).keydown(function(e){

        if(e.keyCode == 27) {

			$('body').css('overflow', 'visible');
			$('.c-single__wrapper').removeClass('active');
        }
    });

};