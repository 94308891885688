import $ from 'jquery';

export default function () {

	$(document).scroll(function() {

		var doc = $(document).scrollTop();
		var footer = $('.c-footer').offset().top;
		var footerHeight = $('.c-footer').innerHeight();
		var copyright = $('.c-copyright').innerHeight();


		if(doc >= footer - footerHeight - copyright) {

			$('.c-sticky__cta').addClass('slideOut');

		} else {
			$('.c-sticky__cta').removeClass('slideOut');
		}

	});

}