
export default function () {

    $('.js-single-link').on('click', function(){
        $('.slick-initialized').slick('unslick');
       if($(window).width() > 767){
           setTimeout(initSlick, 1000);
       }
       else{
           return null;
       }
    });

    $('.c-single__link').on('click', function(){
        $('.slick-initialized').slick('unslick');
        if($(window).width() > 767){
            setTimeout(initSlick, 1000);
        }
        else{
            return null;
        }
    });

    $(window).resize(function() {
        if($(window).width() > 768){
            $('.js-single.active .slick-banner:not(.slick-initialized)').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                fade: true,
                autoplay: false,
                asNavFor: '.slick-nav',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: 'unslick'
                    }
                ]
            });
            $('.js-single.active .slick-nav:not(.slick-initialized)').slick({
                slidesToShow: 10,
                slidesToScroll: 1,
                asNavFor: '.slick-banner',
                dots: false,
                centerMode: false,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: 'unslick'
                    }
                ]
            });
        }
    })

    function initSlick(){
        $('.slick-banner').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            autoplay: false,
            asNavFor: '.slick-nav',
            responsive: [
                {
                    breakpoint: 768,
                    settings: 'unslick'
                }
            ]
        });

        $('.slick-nav').slick({
            slidesToShow: 10,
            slidesToScroll: 1,
            asNavFor: '.slick-banner',
            dots: false,
            centerMode: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: 'unslick'
                }
            ]
        });



    }

    // $(window).on('resize', function(){
    //     responsiveSlide();
    // });
    //
    // function responsiveSlide(){
    //     if($(window).width() > 767){
    //         if($('.slick-banner').hasClass('slick-slider')){
    //             return null;
    //         }
    //         else{
    //             initSlick();
    //         }
    //     }
    //     else if($(window).width() < 768){
    //         if($('.slick-nav').hasClass('slick-slider')){
    //             $('.slick-banner').slick('unslick');
    //             $('.slick-nav').slick('unslick');
    //         }
    //         else{
    //             return null;
    //         }
    //     }
    // }
}