import './modules/helpers';
import start from './modules/router';
import mobileNav from './modules/mobileNav';
import loader from './modules/loader';
import scroller from './modules/scroller';
import slider from './modules/slider';
import banner from './modules/banner';
import hideFixed from './modules/hideFixed';
import liftup from './modules/liftup';
import tabs from './modules/tabs';
import single from './modules/single';
import sticky from './modules/sticky';

mobileNav();
loader();
scroller();
slider();
banner();
hideFixed();
liftup();
tabs();
single();
sticky();